import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";

import LangContext, { Labels } from "../context/LangContext";
import facebook from "../images/facebook.png";
import tripadvisor from "../images/tripadvisor.png";
import { DirectusPhiloContent } from "../models";

interface Pages {
  contact: Labels;
}

interface Socials {
  facebook: string;
  tripadvisor: string;
}

interface Footer {
  socials: Labels;
  times: Labels;
}

interface Metadata {
  pages: Pages;
  socials: Socials;
  footer: Footer;
}

interface Query {
  site: {
    siteMetadata: Metadata;
  };
  directusPhiloContent: DirectusPhiloContent;
}

const Footer = () => {
  const data: Query = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          pages {
            contact {
              fr
              de
              it
              en
            }
          }
          socials {
            facebook
            tripadvisor
          }
          footer {
            socials {
              fr
              de
              it
              en
            }
            times {
              fr
              de
              it
              en
            }
          }
        }
      }
      directusPhiloContent(page: { eq: "footer" }) {
        name
        page
        order
        translations {
          language
          title
          subtitle
          content
          illustration {
            data {
              full_url
            }
          }
        }
      }
    }
  `);
  const site = data.site.siteMetadata;
  const lang = useContext(LangContext).lang;
  const cms = data.directusPhiloContent.translations.find(i => i.language === lang);
  return (
    <Container fluid={true} className="footer">
      <Row className="footerInfo">
        <Col lg={3} className="address">
          <h5>{site.pages.contact[lang]}</h5>
          <p>
            Le Philosophe - Gilles Maury
            <br />
            Avenue d'Agaune 3<br />
            1890 St-Maurice
          </p>
          <p>+41 24 485 22 03</p>
          <a href="mailto:sabrinici@gmail.com">sabrinici@gmail.com</a>
        </Col>
        <Col lg={3} className="socials">
          <h5>{site.footer.socials[lang]}</h5>
          <a href={site.socials.facebook}>
            <img src={facebook} alt="facebook" />
          </a>
          <a href={site.socials.facebook}>
            <img src={tripadvisor} alt="tripadvisor" />
          </a>
        </Col>
        <Col lg={3} className="times">
          <h5>{site.footer.times[lang]}</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: `<div> ${cms.content} </div>`,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="copyright" lg={12}>
          ©2024 Restaurant Le Philosophe SA - All rights reserved
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
