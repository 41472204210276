import React from "react";

import Footer from "./footer";
import Header from "./header";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <div>
        <main className="main-container">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
