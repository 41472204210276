import React, { useContext } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, graphql, useStaticQuery } from "gatsby";

import LangContext, { Labels } from "../context/LangContext";
import navLogo from "../images/nav-logo.png";
import { Pages } from "../models";

interface Metadata {
  pages: Pages;
  languages: Labels;
}

const Header = () => {
  // prettier-ignore
  const data: Metadata = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          pages {
            home { fr de it en }
            menu { fr de it en }
            allMenu { fr de it en }
            seasonMenu { fr de it en }
            takeaway { fr de it en }
            galery { fr de it en }
            contact { fr de it en }
          }
          languages { fr de it en }
        }
      }
    }
  `).site.siteMetadata;
  const ctx = useContext(LangContext);
  return (
    <Navbar expand="lg" variant="dark">
      <Navbar.Brand className="headerLogo">
        <Link to="/">
          <img src={navLogo} alt="logoHeader" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Navbar.Text>
            <Link to="/">{data.pages.home[ctx.lang]}</Link>
          </Navbar.Text>
          <NavDropdown title={data.pages.menu[ctx.lang]} id="menuDropdown">
            <NavDropdown.ItemText>
              <Link to="/carte-menu">{data.pages.allMenu[ctx.lang]}</Link>
            </NavDropdown.ItemText>
            <NavDropdown.ItemText>
              <Link to="/carte-saison">{data.pages.seasonMenu[ctx.lang]}</Link>
            </NavDropdown.ItemText>
          </NavDropdown>
          <Navbar.Text>
            <Link to="/galerie">{data.pages.galery[ctx.lang]}</Link>
          </Navbar.Text>
          <Navbar.Text>
            <Link to="/contact">{data.pages.contact[ctx.lang]}</Link>
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end languageDropdown">
        <NavDropdown title={data.languages[ctx.lang]} id="languageDropdown">
          {Object.keys(data.languages).map((key: keyof Labels) => {
            return (
              <NavDropdown.Item key={key} onClick={() => ctx.setLang(key)}>
                {data.languages[key]}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Header;
